import React from 'react';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';

import 'bootstrap/dist/css/bootstrap.css';
import '../style/global.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import style from './index.module.scss';
import GlobalFooter from '@bt-react/components/GlobalFooter';

import { useRouter } from 'next/router';
import Layout from '../components/Layout/Layout';
import './fonts.css';

// contexts
import { UserProfileWrapper } from '../context/UserProfileContext';
import { SeriesWrapper } from '../context/SeriesContext';
import {TalksFiltersWrapper} from '../context/TalksFilters';
import {ChannelWrapper} from '../context/ChannelContext';

// Components
import HeaderContainer from '../components/HeaderContainer/HeaderContainer';

// Commented out until we can properly build a screen page  for function.
//import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

type AppOwnProps = { hostUrl: string; env: string };

export default function PortalApp({
  Component,
  pageProps,
  env,
  hostUrl,
}: AppProps & AppOwnProps) {
  const router = useRouter();
  const pagePath = router.asPath; // returns the query params
  const pageName = router.pathname;

  // Remove the searcbar in the header component on the homepage & Search pages.
  // for homepage use pagePath as pageName returns no value.
  // we should be able to improve this?
  let headerSearchBar;
  if (pagePath === '/' || pageName === '/search' || pageName === '/404') {
    headerSearchBar = false;
  } else {
    headerSearchBar = true;
  }

  return (
    <Layout>
      <div className={style['portal-app']}>
        <div className={style['content-wrap']}>
          <UserProfileWrapper>
            <HeaderContainer
              env={env}
              headerSearchBar={headerSearchBar}
              hostUrl={hostUrl}
            />
            <SeriesWrapper>
              <ChannelWrapper>
             <TalksFiltersWrapper>
                <main>
                  <Component {...pageProps} />
                </main>
             </TalksFiltersWrapper>
             </ChannelWrapper>
            </SeriesWrapper>
          </UserProfileWrapper>
        </div>

        <GlobalFooter environment={env} />
      </div>
    </Layout>
  );
}

PortalApp.getInitialProps = async (
  context: AppContext
): Promise<AppOwnProps & AppInitialProps> => {
  const ctx = await App.getInitialProps(context);
  return {
    ...ctx,
    env: global.process.env.ENV ? global.process.env.ENV : 'prod',
    hostUrl: global.process.env.HOST
      ? global.process.env.HOST
      : 'https://www.brighttalk.com',
  };
};
