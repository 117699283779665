import React, { createContext, useState, useContext } from 'react';
import { Channel } from '../libs/interfaceProps/channelProps';

interface ChannelContextType {
  channelData: Channel | undefined;
  setChannelData: (channel: Channel) => void;
  userIsChannelOwner: boolean;
  setUserIsChannelOwner: (isOwner: boolean) => void;
  isManager: boolean;
  setIsManager: (isManager: boolean) => void;
  channelVisibility: string | undefined;
  setChannelVisibility: (channelVisibility: string) => void;
  seriesCount: number | undefined;
  setSeriesCount: (seriesCount: number) => void;
}

// Create a context to store the channel profile data
const ChannelContext = createContext<ChannelContextType | undefined>(undefined);

export function ChannelWrapper({ children }: { children: React.ReactNode }) {
  const [channelData, setChannelData] = useState<Channel | undefined>();
  const [userIsChannelOwner, setUserIsChannelOwner] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [channelVisibility, setChannelVisibility] = useState<
    string | undefined
  >();
  const [seriesCount, setSeriesCount] = useState<number | undefined>();

  return (
    <ChannelContext.Provider
      value={{
        channelData,
        setChannelData,
        userIsChannelOwner,
        setUserIsChannelOwner,
        isManager,
        setIsManager,
        channelVisibility,
        setChannelVisibility,
        seriesCount,
        setSeriesCount,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
}

export const useChannelContext = () => {
  const context = useContext(ChannelContext);

  if (!context) {
    throw new Error('useChannelContext must be used within ChannelWrapper');
  }
  return context;
};
