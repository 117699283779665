import React, { createContext, useState, useContext } from 'react';
interface talksFiltersContextType {
  talksCat: string[];
  setTalksCat: (talksCat: string[]) => void;
  talksStatusFilter: string;
  setTalksStatusFilter: (talksStatusFilter: string) => void;
  talksSearchFilter: string;
  setTalksSearchFilter: (talksSearchFilter: string) => void;
  featuredTalk: string;
  setFeaturedTalk: (featuredTalk: string) => void;
}

// Create a context to store the filters for sorting talks
const TalksFiltersContext = createContext<talksFiltersContextType | undefined>(
  undefined
);

export function TalksFiltersWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const [talksCat, setTalksCat] = useState<string[]>([]);
  const [featuredTalk, setFeaturedTalk] = useState<string>('');
  const [talksStatusFilter, setTalksStatusFilter] = useState<string>('');
  const [talksSearchFilter, setTalksSearchFilter] = useState<string>('');

  return (
    <TalksFiltersContext.Provider
      value={{
        talksCat,
        setTalksCat,
        talksStatusFilter,
        setTalksStatusFilter,
        talksSearchFilter,
        setTalksSearchFilter,
        featuredTalk,
        setFeaturedTalk,
      }}
    >
      {children}
    </TalksFiltersContext.Provider>
  );
}

export function useTalksFilters() {
  const context = useContext(TalksFiltersContext);
  if (!context) {
    throw new Error('talksFiltersContext must be used within siteWideWrapper');
  }
  return context;
}
