import { Series } from '../libs/interfaceProps/seriesProps';
import React, { createContext, useState, useContext } from 'react';

interface SeriesContextType {
  series: Series;
  setSeries: (series: Series) => void;
  isMultiReg: boolean | null;
  setIsMultiReg: (isMultiReg: boolean) => void;
  isVariableMultiReg: string[] | null;
  setIsVariableMultiReg: (isVariableMultiReg: string[]) => void;
  triggerReg: boolean | null;
  setTriggerReg: (triggerReg: boolean) => void;
  modal: boolean;
  setModal: (modal: boolean) => void;
  RegisterRequest: RegisterRequest;
  setRegisterRequest: (RegisterRequest: RegisterRequest) => void;
  episodesTotal: number | null;
  setEpisodesTotal: (episodesCount: number) => void;
  userColor: boolean | false;
  setUserColor: (userColor: boolean) => void;
}
interface RegisterRequest {
  type: string;
  isFeatured?: boolean;
  data?: {
    channelId: string;
    id: string;
    summitId?: string;
    status?: string;
    index?: string;
  };
}
// Create a context to store the series (array of numbers)
const SeriesContext = createContext<SeriesContextType | undefined>(undefined);

export function SeriesWrapper({ children }: { children: React.ReactNode }) {
  const [series, setSeries] = useState<Series>();
  const [isMultiReg, setIsMultiReg] = useState<boolean>(false);
  const [isVariableMultiReg, setIsVariableMultiReg] = useState<string[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [RegisterRequest, setRegisterRequest] = useState<RegisterRequest>();
  const [triggerReg, setTriggerReg] = useState<boolean>(false);
  const [episodesTotal, setEpisodesTotal] = useState<number>();
  const [userColor, setUserColor] = useState<boolean>(false);

  return (
    <SeriesContext.Provider
      value={{
        series,
        setSeries,
        isMultiReg,
        setIsMultiReg,
        isVariableMultiReg,
        setIsVariableMultiReg,
        modal,
        setModal,
        RegisterRequest,
        setRegisterRequest,
        triggerReg,
        setTriggerReg,
        episodesTotal,
        setEpisodesTotal,
        userColor,
        setUserColor,
      }}
    >
      {children}
    </SeriesContext.Provider>
  );
}

export const useSeriesContext = () => {
  const context = useContext(SeriesContext);

  if (!context) {
    throw new Error('useSeriesContext must be used within SeriesWrapper');
  }
  return context;
};
